import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import tinymce from 'tinymce';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FileLanguage } from '../../store/models';
import { contentChanged, selectContent } from '../../store/slices/contentSlice';
import { _isActionDelete, focusedItemName } from '../../store/slices/editorSlice';
import { _topics } from '../../store/slices/topicSlice';
import { MAIL_VALIDATION_REGEX, PHONE_NUMBER_VALIDATION_REGEX, SMS_VALIDATION_REGEX } from '../../util/constants';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		focus: {
			cursor: 'text',
		},
	})
);

const isValidUrl = (urlString) => {
	// "^(https?:\\/\\/)?" + // validate protocol
	// "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
	// "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
	// "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
	// "(\\?[;&a-z\\d%_.~+=-]*)?", // validate query string
	var urlPattern = new RegExp(
		'^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?',
		'i'
	);
	// validate fragment locator
	return !!urlPattern.test(urlString);
};

// all the properties of a "content.language" (FileLanguage) item and a few others
export interface EditTextProps extends FileLanguage {
	isFocused: boolean;
	newElement: boolean;
	contentId: string;
}

export const EditText = (props: EditTextProps) => {
	const { content, language, isFocused, newElement, contentId } = props;
	const editorRef = useRef(null);
	const valueRef = useRef(content);
	const dirtyRef = useRef(false);
	const languageRef = useRef(language);
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const onContentChanged = bindActionCreators(contentChanged, dispatch);
	const topics = useAppSelector(_topics);
	const [isDirty, setIsDirty] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const isActionDelete = useAppSelector(_isActionDelete);
	const onFocusedItemName = bindActionCreators(focusedItemName, dispatch);
	const onSelectContent = bindActionCreators(selectContent, dispatch);
	const [editorValue, setEditorValue] = useState(content);

	//useEffect to set the memoized valueRef to the current content
	useEffect(() => {
		valueRef.current = editorValue;
		dirtyRef.current = isDirty;
		languageRef.current = language;
	}, [editorValue, isDirty, language]);

	//useEffect to act as a lifecyle method for when the component unmounts. Need to check if isDirty, and if so, save the content to the DB
	useEffect(() => {
		// clean-up function
		return () => {
			if (dirtyRef.current) {
				onContentChanged({
					language: languageRef.current,
					content: valueRef.current,
					id: contentId,
				});
				setIsDirty(false);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() => {
			if (editorRef?.current) {
				editorRef.current['availableTopics'] = Object.values(topics);
			}
		},
		// eslint-disable-next-line
		[topics]
	);

	// THIS MIGHT BE ADDING A NEW COPY OF THIS PLIUGIN EACH TIME THE EDITOR IS RE-RENDERED, NEED TO COME BACK TO THIS 3-30-23
	tinymce.util.Tools.resolve('tinymce.PluginManager').add('topicLink', function (editor, url) {
		editor.ui.registry.addButton('topicLink', {
			text: '',
			icon: 'link',
			onAction: function (e) {
				const node = editor.selection.getNode();
				let nodeValue = '';
				let nodeText = '';
				let isTopicNode = false;
				let tabName = 'none';
				if (node.classList.contains('customTopicLink')) {
					isTopicNode = true;
					nodeText = node.innerText;
				}
				if (node.hasAttribute('href')) {
					nodeValue = node.getAttribute('href');
					if (nodeValue.includes('/topic/')) tabName = 'page';
					else if (
						!nodeValue.includes('/topic/') &&
						(nodeValue.includes('http://') || nodeValue.includes('https://'))
					)
						tabName = 'web';
					else if (nodeValue.includes('mailto:')) tabName = 'eMail';
					else if (nodeValue.includes('tel:')) tabName = 'phone';
					else if (nodeValue.includes('sms:')) tabName = 'sms';
				}

				const selection = editor.selection.getContent({ format: 'text' });

				const options = [
					...editor['availableTopics'].map((a) => {
						return {
							value: a['id'],
							text: a['name'][0]['name'],
						};
					}),
				];

				const instanceApi = editor.windowManager.open({
					title: 'Link',
					body: {
						type: 'tabpanel',
						tabs: [
							{
								name: 'none',
								title: 'None',
								items: [
									{
										type: 'htmlpanel', // component type
										html: '<div>Nothing linked yet!</div>',
										autofocus: false,
									},
								],
							},
							{
								name: 'page',
								title: 'Page',
								items: [
									{
										type: 'selectbox',
										name: 'topic',
										items: options,
										autofocus: false,
									},
								],
							},
							{
								name: 'web',
								title: 'Web Address',
								items: [
									{
										type: 'input',
										name: 'web',
										placeholder: 'https://liiingo.com',
										autofocus: false,
									},
								],
							},
							{
								name: 'eMail',
								title: 'Email',
								items: [
									{
										type: 'input',
										name: 'email',
										placeholder: 'example@email.com',
										autofocus: false,
									},
								],
							},
							{
								name: 'phone',
								title: 'Call',
								items: [
									{
										type: 'input',
										name: 'call',
										placeholder: '(123) 456-7890',
										autofocus: false,
									},
								],
							},
							{
								name: 'sms',
								title: 'SMS',
								items: [
									{
										type: 'input',
										name: 'sms',
										placeholder: '(123) 456-7890',
										autofocus: false,
									},
								],
							},
						],
					},
					buttons: [
						{
							type: 'cancel',
							text: 'Close',
						},
						{
							type: 'submit',
							text: 'Save',
							primary: true,
						},
					],
					initialData: {
						topic: nodeValue.includes('/topic/') ? nodeValue.split('/topic/')[1] : '',
						web: !nodeValue.includes('/topic/') && (nodeValue.includes('http') ? nodeValue : ''),
						email: nodeValue.includes('mailto:') ? nodeValue.split('mailto:')[1] : '',
						call: nodeValue.includes('tel:') ? nodeValue.split('tel:')[1] : '',
						sms: nodeValue.includes('sms:') ? nodeValue.split('sms:')[1] : '',
					},
					onTabChange: function (dialogApi, details) {
						tabName = details.newTabName;
					},
					onSubmit: function (e) {
						let linkContent, hrefAttr, topicValAttr, targetVal;
						linkContent = '';
						hrefAttr = '';
						topicValAttr = '';
						targetVal = '';
						let attrList = [
							'class',
							'style',
							'href',
							'target',
							'data-topicval',
							'data-mce-href',
							'data-mce-style',
							'data-mce-selected',
						];
						if (tabName === 'page') {
							// page link
							hrefAttr = `${window.location.origin}/topic/${e.getData().topic}`;
							topicValAttr = `data-topicval=${e.getData().topic}`;
							targetVal = `target="_blank"`;
						} else if (tabName === 'web') {
							// web address link
							topicValAttr = '';
							if (isValidUrl(e.getData().web)) {
								if (!e.getData().web.includes('http')) {
									hrefAttr = `https://${e.getData().web}`;
								} else {
									hrefAttr = e.getData().web;
								}
							} else {
								editor.windowManager.alert('Invalid URL!');
								return false;
							}
						} else if (tabName === 'eMail') {
							// eMail Link
							topicValAttr = '';
							if (e.getData().email.match(MAIL_VALIDATION_REGEX)) {
								hrefAttr = `mailto:${e.getData().email}`;
							} else {
								editor.windowManager.alert('Invalid Email!');
								return false;
							}
						} else if (tabName === 'phone') {
							// phone link
							topicValAttr = '';
							if (e.getData().call.match(PHONE_NUMBER_VALIDATION_REGEX)) {
								hrefAttr = `tel:${e.getData().call}`;
							} else {
								editor.windowManager.alert('Invalid Phone Number!');
								return false;
							}
						} else if (tabName === 'sms') {
							// sms link
							topicValAttr = '';
							if (e.getData().sms.match(SMS_VALIDATION_REGEX)) {
								hrefAttr = `sms:${e.getData().sms}`;
							} else {
								editor.windowManager.alert('Invalid Phone Number!');
								return false;
							}
						}

						linkContent = `<a href="${hrefAttr}"
                            style="text-decoration: underline; color: blue; cursor: pointer;"
                            class="customTopicLink"
                            ${targetVal}
                            ${topicValAttr}
                        >${nodeText !== '' ? nodeText : selection}</a>`;

						if (tabName === 'none') {
							linkContent = `<span>${nodeText !== '' ? nodeText : selection}</span>`;
						}

						if (isTopicNode) {
							if (selection) editor.insertContent(linkContent);
							else {
								if (hrefAttr === '') {
									attrList.forEach((attr) => editor.selection.getNode().removeAttribute(attr));
								} else {
									editor.selection.getNode().setAttribute('href', hrefAttr);
									if (topicValAttr !== '') {
										editor.selection.getNode().setAttribute('data-topicval', e.getData().topic);
									}
								}
							}
						} else if (selection) {
							editor.selection.setContent(linkContent);
						} else {
							editor.insertContent(linkContent);
						}
						e.close();
					},
				});

				instanceApi.showTab(tabName);
			},
		});

		return {
			getMetadata: function () {
				return { name: 'topicLink' };
			},
		};
	});

	const tinyInit = {
		menubar: 'false',
		inline: true,
		// preformatted: true,
		fixed_toolbar_container: '#tinyToolbarSpot', //use this to place the menu bar inside another element
		font_size_formats:
			'8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 44px 46px 48px 50px 52px 54px 56px 58px 60px 62px 64px 66px 68px 70px 72px 74px 76px 78px 80px 82px 84px 86px 88px 90px 92px 94px 96px 98px 100px',
		font_family_formats:
			'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Open Sans=Open Sans; Oswald=oswald; Roboto=roboto; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;',
		content_style: '.mce-edit-focus { outline: none; }, ',
		default_link_target: '_blank',
		// target_list: false,
		// link_title: false,
		preview_styles: 'font-size color',
		browser_spellcheck: true,
		promotion: false,
		// apply_source_formatting: true,
		// convert_urls: false,
		// extended_valid_elements:
		// 	'span[class|style],i[class|style],script[type|src],style[type|src|href],link[href|rel],+body[style]',
		// custom_elements: 'style,link,~link,span',
		// element_format: 'html' as 'html',
		plugins: 'code lists advlist topicLink link accordion',
		toolbar: 'accordion',
		// newline_behavior: 'default',
		// setup: function (editor) {
		// 	tinymce.PluginManager.load('dropdownmenu', '/tinyMenuPlugin.js');
		// },
	};

	const setEditorDesign = () => {
		if (editorRef?.current) {
			editorRef.current.mode.set('design');
			setIsEdit(true);
		}
	};

	const setEditorReadonly = () => {
		if (!isEdit && editorRef?.current) {
			editorRef.current.mode.set('readonly');
		}
	};

	return (
		<div
			className={isFocused && isEdit ? classes.focus : null}
			onDoubleClick={setEditorDesign}
			onMouseDown={setEditorReadonly}
		>
			<Editor
				apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
				plugins={'code lists advlist topicLink link accordion'}
				toolbar={[
					'code topicLink accordion',
					'bold italic underline strikethrough',
					'blocks fontfamily fontsize',
					'alignleft aligncenter alignright alignjustify',
					'bullist numlist outdent indent',
					'forecolor backcolor',
					'undo redo | removeformat',
				]}
				onInit={(evt, editor) => {
					editor['availableTopics'] = Object.values(topics);
					editorRef.current = editor;
					// highlight the editor text if this is a new content item
					if (newElement) {
						setTimeout(() => {
							editorRef.current.focus();
							editorRef.current.selection.select(editorRef.current.getBody(), true);
						}, 1);
					}

					// add the event listener to the editor
					editor.on('blur', function (e) {
						if (dirtyRef.current) {
							onContentChanged({
								language: languageRef.current,
								content: valueRef.current,
								id: contentId,
							});
							setIsDirty(false);
						}
					});
				}}
				initialValue={content}
				init={tinyInit}
				onDirty={() => setIsDirty(true)}
				onBlur={(e) => {
					if (isActionDelete) {
						// Here, prevent onBlur event when we click delete button on ActionMenu
						e.stopImmediatePropagation();
					} else if (e.focusedEditor === null) {
						if (dirtyRef.current) {
							onContentChanged({
								language: languageRef.current,
								content: valueRef.current,
								id: contentId,
							});
							setIsDirty(false);
						}
						onSelectContent('');
						onFocusedItemName('');
					}
				}}
				onEditorChange={(a, editor) => {
					setEditorValue(a);
				}}
			/>
		</div>
	);
};
