import { Checkbox, makeStyles } from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import { useAppSelector } from '../store/hooks';
import { _topics } from '../store/slices/topicSlice';
import { _activeLanguageCode } from '../store/slices/editorSlice';
import { _sections } from '../store/slices/sectionSlice';

export interface SectionList {
	id: string;
	name: { name: string; language: string }[];
	topicOrder: string[];
}

const useInnerStyle = makeStyles((theme) => ({
	tree: {
		backgroundColor: '#FFFFFF',
		minHeight: '300px',
		overflow: 'auto',
		'& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
			backgroundColor: '#FFFFFF',
		},
		'& .MuiTreeItem-content': {
			backgroundColor: '#FFFFFF',
			display: 'block',
		},
		'& .MuiTreeItem-label': {
			backgroundColor: '#FFFFFF',
			display: 'block',
		},
	},

	leaf: {
		backgroundColor: '#FFFFFF',
		paddingLeft: 0,
		display: 'block',
	},
}));

export const ContentTree = (props: {
	selectedItems: string[]; // Tracking what element has been checked
	handleToggle: (id: string, isSection: boolean) => () => void;
	handleSelectAll: (check: boolean) => void;
}) => {
	//
	const { handleToggle, selectedItems, handleSelectAll } = props;

	// Selecting data from the Redux store
	const sections = useAppSelector(_sections);
	const topics = useAppSelector(_topics);
	const activeLanguage = useAppSelector(_activeLanguageCode);

	// Creating arrays of all section IDs, page IDs, and combined array of Sections and Pages
	const allSections = Object.keys(sections);
	const allPages = Object.keys(sections).flatMap((sectionId) => sections[sectionId].topicOrder);
	const allItems = [...allSections, ...allPages];

	// Check if all items are selected
	const isAllSelected = allItems.every((item) => selectedItems.includes(item));

	const classes = useInnerStyle();

	// This function renders a sections details and topics.
	// It maps over the sections array and get their names and IDs
	// For each section ID, it:
	//   1. Retrieves the section object from the 'sections' object
	//   2. Extracts the section name based on active language or from the first name object in the array
	const renderTree = (sections: { [key: string]: SectionList }) => {
		return (
			<TreeView className={classes.tree} multiSelect expanded={['selectAll']}>
				{/* Rendering the "Select All" option */}
				<TreeItem
					key="selectAll"
					nodeId="selectAll"
					className={classes.leaf}
					label={
						<div className={classes.leaf}>
							<Checkbox
								checked={isAllSelected}
								indeterminate={selectedItems.length > 0 && !isAllSelected}
								onChange={() => handleSelectAll(!isAllSelected)}
								inputProps={{ 'aria-label': 'selectAll' }}
								color="primary"
							/>
							Select All
						</div>
					}
				>
					{/* Rendering the tree of sections and topics */}
					<TreeView className={classes.tree} expanded={allItems}>
						{Object.keys(sections).map((id) => {
							const section = sections[id];
							const sectionNames = section.name;

							// Function to render topics within a section
							// It maps over the topicOrder array of the section, which contains topic IDs.
							// For each topic ID, it:
							//   1. Retrieves the topic object from the 'topics' object
							//   2. Extracts the topic name based on active language or from the first name object in the array
							const renderTopics = (section) =>
								section.topicOrder.map((topicId) => {
									const topic = topics[topicId];
									const topicName =
										topic?.name?.find((topicName) => topicName.language === activeLanguage).name ||
										topic?.name[0]?.name;
									return (
										<TreeItem
											key={topicId}
											itemID={String(topicId)}
											label={
												<div className={classes.leaf}>
													<Checkbox
														checked={selectedItems.includes(topicId)}
														onChange={handleToggle(topicId, false)}
														inputProps={{ 'aria-label': topicId }}
														color="primary"
													/>
													{topicName}
												</div>
											}
											nodeId={String(topicId)}
										/>
									);
								});

							// Rendering section with its topics
							return (
								<>
									<TreeItem
										key={section.id}
										itemID={section.id}
										label={
											<div className={classes.leaf}>
												<Checkbox
													color="primary"
													checked={selectedItems.includes(section.id)}
													inputProps={{ 'aria-label': section.id }}
													onChange={handleToggle(section.id, true)}
												/>
												{sectionNames.find((name) => name.language === activeLanguage)?.name ||
													sectionNames[0].name}
											</div>
										}
										nodeId={section.id}
										className={classes.leaf}
									>
										<TreeView>{renderTopics(section)}</TreeView>
									</TreeItem>
								</>
							);
						})}
					</TreeView>
				</TreeItem>
			</TreeView>
		);
	};

	// Rendering the entire content tree (Select All, Sections, and Topics)
	return <>{renderTree(sections)}</>;
};
